// Routers Action Types
export const SET_CURRENT_SEARCHING_COLLECTION = 'SET_CURRENT_SEARCHING_COLLECTION';
export const SET_CURRENT_SEARCHING_TERM = 'SET_CURRENT_SEARCHING_TERM';

// Employee Action Types
export const ADD_CONTRIBUTOR = 'ADD_CONTRIBUTOR';
export const RESET_CONTRIBUTOR = 'RESET_CONTRIBUTOR';

// Company Action Types
export const SET_COMPANY = 'SET_COMPANY';
export const SET_PAYMENT_IN_CASH = 'SET_PAYMENT_IN_CASH';
export const SET_MAX_DELIVERIES = 'SET_MAX_DELIVERIES';
export const SET_DELIVERY_WITH_RETURN= 'SET_DELIVERY_WITH_RETURN';
export const RESET_COMPANY = 'RESET_COMPANY';

// Invoice Action Type
export const SET_INVOICE_ARRAY = 'SET_INVOICE_ARRAY';
export const RESET_INVOICES = 'RESET_INVOICES';