import { SET_INVOICE_ARRAY, RESET_INVOICES } from "../../types";
import { InvoiceData } from "../../../models/Entregas";
import { Action } from "../../../models/Core.models";

const INITIAL_STATE: InvoiceData[] = []

export default function invoices(state = INITIAL_STATE, action: Action<InvoiceData[]>): InvoiceData[] {
  switch (action.type) {
    case SET_INVOICE_ARRAY:
      return action.payload as InvoiceData[];
    case RESET_INVOICES:
      return INITIAL_STATE;
    default:
      return state;
  }
}
