// import external modules
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// import internal(own) modules
import ReduxToastr from "react-redux-toastr";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/storeConfig/store";

import "font-awesome/css/font-awesome.min.css";

import "./index.scss";
import Spinner from "./components/spinner/spinner";

const LazyApp = lazy(() => import("./app/app"));

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Spinner />} persistor={persistor}>
      <Suspense fallback={<Spinner />}>
        <LazyApp />
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
