import { RESET_COMPANY, SET_COMPANY, SET_PAYMENT_IN_CASH, SET_DELIVERY_WITH_RETURN } from '../../types';

import { Company } from '../../../models/Company';
import { Action } from '../../../models/Core.models';

const INITIAL_STATE: Company = {
  id: "",
  nomeRepresentante: "",
  sobrenomeRepresentante: "",
  nomeEmpresa: "",
  telefoneRepresentante: "",
  emailRepresentante: "",
  idRepresentante: "",
  cepEmpresa: "",
  cnpjEmpresa: "",
  estadoEmpresa: "",
  cidadeEmpresa: "",
  ruaEmpresa: "",
  numeroRuaEmpresa: "",
  bairroEmpresa: "",
  complementoRuaEmpresa: "",
  companyName: "",
  fantasyName: "",
  username: "",
  cnpj: "",
  cellNumber: "",
  formattedAddress: "",
  type: "",
  status: "",
  servicos: "",
  address: {
    cep: "",
    street: "",
    addressNumber: "",
    address: "",
    neighborhood: "",
    complement: "",
    cidade: "",
    bairro: "",
    city: "",
    state: "",
    location: {
      type: "",
      coordinates: [],
    }
  },
  maximumDeliveriesForDeliverman: undefined,
  paymentInCash: undefined,
  deliveryWithReturn: undefined,
  ifoodIntegration: {
    authorizedAt: null,
    finishedAt: null,
    requestedAt: null,
    requestedAuthorizationAt: null,
    externalStoreId: null,
    observation: null,
    status: "not requested",
  },
  directRequestEnabled: undefined,
};

export default function company(state = INITIAL_STATE, action: Action<Company | boolean>): Company {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...action.payload as Company,
      };
    case SET_PAYMENT_IN_CASH:
      return {
        ...state,
        paymentInCash: action.payload as boolean,
      }
    case RESET_COMPANY:
      return {
        ...INITIAL_STATE,
      };
    case SET_DELIVERY_WITH_RETURN:
      return {
        ...state,
        deliveryWithReturn: action.payload as boolean,
      }
    default:
      return state;
  }
}
